import { OptionType } from 'components/dropdown/types';

import {
  DeviceVendor,
  IDevice,
  IDeviceCategory,
  IDeviceUser,
  IInvitation,
  IPaginationInput,
} from '../../types/common';

export type IGetAllDevicesRequest = {
  searchTerm: string;
  pagination: IPaginationInput;
};

export type IGetDeviceByIDResponse = {
  getDeviceById: IDevice;
};

export type IGetDeviceByIDRequest = {
  id: string;
};

export type IDeleteDeviceResponse = {
  deleteDeviceUserByIdForAdmin: boolean;
};

export type IUnbindDeviceResponse = {
  unbindDevice: boolean;
};
export type IUnbindDeviceRequest = {
  deviceId?: string;
  deviceUserId?: string;
  deviceUnbindRequestId: string;
};

export type IDeleteDeviceRequest = {
  id: string;
};

export enum UserAccessType {
  member = 'member',
  owner = 'owner',
}

export type IGetDeviceUsersResponse = {
  getDeviceUsers: {
    totalCount: number;
    nodes: IDeviceUser[];
  };
};

export type IGetDeviceUserResponse = {
  getDeviceUserForAdmin: IDeviceUser;
};

export type IDeviceUserInput = {
  accessType?: UserAccessType;
  pagination?: IPaginationInput;
  roomId?: string;
  userId?: string;
  category?: IDeviceCategory;
  searchTerm?: string;
  deviceId?: string;
  vendor?: DeviceVendor;
  modelName?: string;
  countryId?: string;
};

export type IGetDeviceUsersRequest = {
  params: IDeviceUserInput;
  userId?: string;
  isFilter?: boolean;
};

export type IDeviceModelsInput = {
  category?: IDeviceCategory;
};

export type IGetDeviceModelsRequest = {
  params: IDeviceModelsInput;
};

export type IDeviceModelsResponse = {
  category: IDeviceCategory;
  models: [string];
};

export type IGetDeviceModelsResponse = {
  getDeviceModels: [IDeviceModelsResponse];
};

export enum InvitationClaimStatus {
  TO_BE_CLAIMED = 'TO_BE_CLAIMED',
  CLAIMED = 'CLAIMED',
  REVOKED = 'REVOKED',
  EXPIRED = 'EXPIRED',
  RELIEVED = 'RELIEVED',
}

export type IDeviceInviteInput = {
  deviceId: string;
  userId: string;
  paginationInput: IPaginationInput;
  statusIn: InvitationClaimStatus[];
};

export type IGetDeviceInvitesRequest = {
  params: IDeviceInviteInput;
};

export type IGetDeviceInvitesResponse = {
  getInvitationForAdmin: {
    totalCount: number;
    nodes: [IInvitation];
  };
};

type IRoomUserInput = {
  searchTerm: string;
  paginationInput: IPaginationInput;
};

export type IGetAllRoomsByUserRequest = {
  userId: string;
  params: IRoomUserInput;
};

export type IGetAllRoomsByUserResponse = {
  getRoomsByUserId: {
    totalCount: number;
    nodes: [{ id: string; name: string }];
  };
};

export enum DeviceDetailsForm {
  NAME = 'name',
  ROOM_ID = 'roomId',
  ROOM_NAME = 'roomName',
  STATUS = 'status',
  CATEGORY = 'category',
  OWNER_ID = 'ownerId',
  OWNER_NAME = 'ownerName',
  LOCATION = 'location',
  VENDOR = 'vendor',
  MODEL_NAME = 'modelName',
  PAIRED_AT = 'pairedAt',
  LAST_FILTER_RESET_AT = 'lastFilterResetAt',
}

export type IDeviceDetailsForm = {
  name: string;
  roomId: string;
  roomName: string;
  online: boolean;
  category: string;
  ownerId: string;
  ownerName: string;
  location: string;
  vendor: string;
  modelName: string;
  pairedAt: string;
  lastFilterResetAt: string;
};

export type DeviceFilters = {
  deviceSearchText: string;
  selectedUser?: OptionType;
  page: number;
  userSearchText: string;
  userPage: number;
  roomSearchText: string;
  roomPage: number;
  selectedRoom?: OptionType;
  selectedVendor?: OptionType;
  selectedCategory?: OptionType;
  selectedUserType?: OptionType;
  selectedModelName?: OptionType;
  selectedCountry?: OptionType;
};

export type DeviceSlice = {
  filters: {
    deviceList: DeviceFilters;
    userDeviceList: DeviceFilters;
  };
  totalDeviceCount: number;
};

type DeviceFilterType = 'deviceList' | 'userDeviceList';

export type IStringPayload = {
  payload: { data: string; type: DeviceFilterType };
};

export type INumberPayload = {
  payload: { data: number; type: DeviceFilterType };
};

export type IOptionTypePayload = {
  payload: { data: OptionType; type: DeviceFilterType };
};

export enum Tabs {
  DEVICE_DETAILS = 1,
  INVITES = 2,
  ROUTINES = 3,
  GEOFENCING = 4,
  AMBIENT_TEMPERATURE = 5,
  HOURS_OF_USAGE = 6,
  INSIGHTS = 7,
}
